<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-15 16:05:02
 * @LastEditTime: 2021-02-07 15:13:40
 * @LastEditors: yangliao
 * @Description: 绑定客户
 * @FilePath: /netHallOfficialAccounts/src/views/WaterAccount/bindCustomer.vue
-->
<template>
    <div class="bindCustomer-page">
        <div class="header">
            <span>户号:{{dataInfo.wuserNo}}</span>
            <p>{{dataInfo.wuserAddress}}</p>
        </div>
        <div class="content" v-if="dataFlag">
            <p class="title">已{{dataInfo.list && dataInfo.list.length}}个客户绑定用该水账户</p>
            <ul>
                <li v-for="(item, index) in dataInfo.list" :key="index">
                    <div class="first-li">
                        <p>{{item.wuserName}}</p>
                        <em @click="removeBind(index)">解除绑定</em>
                    </div>
                    <div class="last-li">
                        <span>{{ thirdTypeTerms[item.thirdType] ? `${thirdTypeTerms[item.thirdType]}绑定` : ''}}</span>
                        <span>{{item.createdTime}}</span>
                    </div>
                </li>
            </ul>
        </div>
        <no-data v-else />
    </div>
</template>
<script>
import { Dialog, Toast } from 'vant';
import { formatTime } from "@/utils/tools";
import NoData from '@/components/noData';
import { selectWuserNoCust, unbindUser, selectDictEnumByCode } from '../../api/home';

export default {
    name: 'BindCustomer',
    components: {
        NoData
    },
    data() {
        return {
            dataInfo: {},
            thirdTypeTerms: {},
            dataFlag: true,
            custWaterUserRelId: this.$route.query.custWaterUserRelId
        }
    },
    mounted() {
        this.init();
        this.selectWuserNoCust();
    },
    methods: {
        async init () {
            const {status, resultData} = await selectDictEnumByCode('THIRD_TYPE');
            if (status === 'complete') {
                resultData.forEach(item => {
                    this.$set(this.thirdTypeTerms, item.code, item.name);
                })
            }
        },
        async selectWuserNoCust() {
            let arg = {
                custWaterUserRelId: this.custWaterUserRelId
            };
            const { status, resultData } = await selectWuserNoCust(arg);
            if (status === 'complete') {
                // 时间格式转换
                if (resultData && resultData.list && resultData.list.length > 0) {
                    resultData && resultData.list.map((item) => {
                        item.createdTime = formatTime(item.createdTime)
                    })
                    this.dataInfo = resultData;
                    this.dataFlag = true;
                } else {
                    this.dataInfo = [];
                    this.dataFlag = false;
                    this.$router.push({ path: '/' });
                }
            }
        },
        removeBind(index) {
            let that = this;
            Dialog.confirm({
                // title: '标题',
                message: '您确定要解除该客户的绑定吗?',
                confirmButtonText: '解除绑定',
                confirmButtonColor: '#3C7EF0',
                cancelButtonText: '不解除',
                cancelButtonColor: '#767E8C',
                className: 'removeDialog'
            })
            .then(() => {
                const custRealId = that.dataInfo.list[index].custRealId
                const wuserNo = that.dataInfo.list[index].wuserNo
                console.log(111);
                that.unbindUser(custRealId, wuserNo);
            })
            .catch(() => {
                console.log('取消')
            });
        },
        async unbindUser(arg1, arg2) {
            const {status, resultData} = await unbindUser(arg1, arg2);
            if (status === 'complete') {
                Toast('已解除该客户的绑定')
                this.selectWuserNoCust();
            }
        }
    }
}
</script>

<style lang="less" scoped>
.bindCustomer-page {
    .header {
        margin: 16px 16px 0px 16px;
        background: #ffffff;
        border-radius: 8px;
        padding: 16px;
        span {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0,0,0,0.65);
            letter-spacing: 0;
        }
        p {
            margin: 0px;
            padding-top: 3px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            letter-spacing: 0;
        }
    }
    .content {
        .title {
            margin: 0px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0,0,0,0.45);
            letter-spacing: 0;
            height: 50px;
            line-height: 50px;
            padding-left: 20px;
        }
        ul {
            margin-left: 16px;
            margin-right: 16px;
            li {
                padding-left: 16px;
                padding-right: 16px;
                background-color: #ffffff;
                border-radius: 8px;
                margin-bottom: 16px;
                .first-li {
                    display: flex;
                    justify-content: space-between;
                    height: 50px;
                    line-height: 50px;
                    position: relative;
                    p {
                        margin: 0px;
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: rgba(0,0,0,0.85);
                    }
                    em {
                        font-style: normal;
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #477BB7;
                        text-align: right;
                    }
                }
                .first-li::after {
                    position: absolute;
                    box-sizing: border-box;
                    content: ' ';
                    pointer-events: none;
                    right: 0px;
                    bottom: 0;
                    left: 0px;
                    border-bottom: 1px solid #ebedf0;
                    -webkit-transform: scaleY(.5);
                    transform: scaleY(.5);
                }
                .last-li {
                    display: flex;
                    justify-content: space-between;
                    height: 50px;
                    line-height: 50px;
                    span {
                        font-family: PingFangSC-Regular;
                        font-size: 12px;
                        color: rgba(0,0,0,0.45);
                    }
                }
            }
        }
    }
    .removeDialog {
        /deep/.van-dialog__message {
            color: #3C4C68 !important;
        }
    }
}
</style>
